@import './leaflet.css';

.rc-slider-handle {
  z-index: 9999;
}
.rc-slider-tooltip {
  z-index: 999;
}
.rc-slider-tooltip .rc-slider-tooltip-inner {
  height: 44px;
  min-width: 124px !important;
}
.rc-slider-tooltip-arrow {
  display: none;
}

img[alt="Google"] {
  display: none;
}

.gmnoprint a {
  height: 5px;
  overflow: hidden;
}
